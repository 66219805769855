import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    popup: null, // used by plugins/popup
    unlockToken: null,
    processType: null,
    isCash: false,
    chosenOfferType: null,
    firstLoad: true,
    // ex. {"firstName": "Jan", "lastName": "Kowalski", "previousLogin": "2021-12-02T14:42:00+01:00"}
    account: null,
    flowStep: {
        allowedSteps: ['verify'],
        config: null,
        layout: null,
        step: null,
    },
    loan: {
        amount: null,
        installments: null,
    },
    offer: null,
    customerAccount: {},
    application: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        processingAgreement: false,
        id45MarketingContactAgreement: false,
        personalIdentityNumber: '',
        idCardSelected: true,
        idCard: {
            number: '',
            releaseDate: null,
            expirationDate: null,
            perpetual: false,
        },
        residenceCard: {
            number: '',
            releaseDate: null,
            expirationDate: null,
        },
        partnersAgreement: false,
    },
    additionalFields: {},
    beforeSignFields: {},
    showTermsOfService: false,
    showPrivacyPolicy: false,
    showFAQ: false,
    showCreditIntermediaryInfo: false,
    whitelabel: {
        price: null,
        title: null,
        img: null,
        link_ogloszenie: null,
        source: {
            productBoxHeaderText: null,
            verificationTemplateVersion: '1',
            installmentCountHidden: false, // for direct, default source has `true` here
            productBoxComponent: {
                name: null,
            },
            chooseButtonOfferText: 'DALEJ',
            overOfferListCopyVersion: 0,
            backgroundUrl: null,
            logo: {
                displayMethod: 'OnlyComperiaratyDisplayMethod',
                imageUrl: null,
            },
        },
    },
    isFieldFocus: false,
    blogContent: {},
    privacyPolicy: 'Wczytuję…',
    termsOfService: 'Wczytuję…',
    trackerId: null, // probably SCE tracking id
    isWindowActive: true,
})

export const mutations = {
    updateField,
    setPopup: (state, payload) => {
        state.popup = payload
    },
    setUnlockToken: (state, payload) => {
        state.unlockToken = payload
    },
    setFirstLoad: (state, payload) => {
        state.firstLoad = payload
    },
    setFlowStep: (state, payload) => {
        state.flowStep = {
            ...payload,
            // @TODO: Move it to backend
            allowedSteps: ['verify', ...payload.allowedSteps, payload.step],
        }
    },
    setCustomerAccount: (state, payload) => {
        state.customerAccount = payload
    },
    setApplicationField: (state, payload) => {
        const { key, value } = payload
        state.application[key] = value
    },
    setOrderData: (state, payload) => {
        const { processType, chosenOfferType, amount, installments, isCash } = payload
        state.processType = processType
        state.chosenOfferType = chosenOfferType
        state.loan.amount = amount
        state.loan.installments = installments
        state.isCash = isCash
    },
    setWhitelabel: (state, payload) => {
        state.whitelabel = payload
    },
    setAllowedSteps: (state, payload) => {
        state.flowStep.allowedSteps = payload
    },
    setTrackerId: (state, payload) => {
        state.trackerId = payload
    },
    setContent: (state, payload) => {
        state.privacyPolicy = payload.privacyPolicy
        state.termsOfService = payload.termsOfService
    },
    setIsWindowActiveStatus: (state, payload) => {
        state.isWindowActive = payload
    },
}

export const getters = {
    getField,
}
