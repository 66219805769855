<template>
    <div class="ecommerce-wrapper theme--light v-application">
        <v-app class="ecommerce">
            <Popup />
            <client-only>
                <div class="page-container">
                    <ToolbarMobile class="hidden-lg-and-up" />
                    <ToolbarDesktop class="hidden-md-and-down" />
                    <nuxt />
                    <Footer class="hidden-lg-and-up" />
                </div>
            </client-only>
            <TermsOfService />
            <PrivacyPolicy />
            <FAQ />
            <CreditIntermediaryInfo />
        </v-app>
        <CookieInfo />
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Popup from '~/components/popups/Popup'
import ToolbarMobile from '~/components/partials/toolbar/ecommerce/ToolbarMobile'
import ToolbarDesktop from '~/components/partials/toolbar/ecommerce/ToolbarDesktop'
import Footer from '~/components/partials/footer/ecommerce/Footer'
import TermsOfService from '~/components/partials/terms-of-service/ecommerce/TermsOfService'
import PrivacyPolicy from '~/components/partials/privacy-policy/ecommerce/PrivacyPolicy'
import FAQ from '~/components/partials/faq/ecommerce/FAQ'
import CreditIntermediaryInfo from '~/components/partials/credit-intermediary-info/ecommerce/CreditIntermediaryInfo'
import CookieInfo from '~/components/partials/cookie-info/ecommerce/CookieInfo'
import cancelOrder from '~/mixins/cancel-order'
import updateConfiguration from '~/plugins/update-configuration'

export default {
    components: {
        Popup,
        ToolbarMobile,
        ToolbarDesktop,
        Footer,
        TermsOfService,
        PrivacyPolicy,
        FAQ,
        CreditIntermediaryInfo,
        CookieInfo,
    },
    mixins: [
        cancelOrder,
    ],
    async fetch () {
        await updateConfiguration(this.$axios, this.$store)
    },
    head () {
        const head = {
            title: 'Wniosek COMFINO',
        }
        if (this.cssUrl) {
            head.link = [
                {
                    rel: 'stylesheet',
                    href: this.cssUrl,
                },
            ]
        }
        return head
    },
    computed: {
        ...mapFields(
            'ecommerce', [
                'store.cssUrl',
            ],
        ),
    },
}
</script>

<style lang="scss">
.ecommerce-wrapper {
    display: block;
}
.ecommerce.theme--light.v-application {
    overflow: hidden;
    color: #4a4a4a;
    font-family: 'Roboto';
    h2 {
        margin-bottom: 8px;
        font-size: 28px;
        font-weight: 400;
        line-height: 1.2;
        &:not(:first-child) {
            margin-top: 24px;
        }
    }
    h3 {
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
    }
    p {
        margin-bottom: 16px;
        line-height: 1.71;
    }
}
.theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  background: white;
}

@include media-breakpoint-up(sm, $vuetify-grid-breakpoints) {
    .ecommerce.theme--light.v-application {
        h2 {
            line-height: 1.71;
        }
    }
}
</style>

<style lang="scss" scoped>
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .page-container {
        flex-direction: row;
    }
}
</style>
