<template>
    <footer class="footer">
        <div class="footer__links">
            <div class="footer__links__item">
                <span @click="showPrivacyPolicy = true">Polityka prywatności</span>
            </div>
            <div class="footer__links__item">
                <span @click="showTermsOfService = true">Regulamin</span>
            </div>
            <div class="footer__links__item mr-auto">
                <span @click="showFAQ = true">Faq</span>
            </div>
            <div class="footer__links__item">
                <span @click="showCreditIntermediaryInfo = true">Informacja pośrednika kredytowego</span>
            </div>
            <div class="footer__links__item copyright">
                <span>Copyright {{ new Date().getFullYear() }} Comfino</span>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    computed: {
        ...mapFields([
            'showTermsOfService',
            'showPrivacyPolicy',
            'showFAQ',
            'showCreditIntermediaryInfo',
        ]),
    },
}
</script>

<style lang="scss" scoped>
.footer {
    padding: 24px 15px;
    background: #fff;
    &__links {
        display: flex;
        flex-wrap: wrap;
        margin-top: -15px;
        margin-left: -15px;
        &__item {
            padding-top: 15px;
            padding-left: 15px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            &:not(.copyright) {
                span {
                    cursor: pointer;
                }
            }
            &.copyright {
                font-weight: 400;
            }
        }
    }
}
@include media-breakpoint-up(lg, $vuetify-grid-breakpoints) {
    .footer {
        padding: 0;
        &__links {
            flex-direction: column;
            margin: 0;
            &__item {
                padding: 0;
                &:not(.copyright) {
                    margin-bottom: 8px;
                }
                &.copyright {
                    margin-top: 64px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}
</style>
