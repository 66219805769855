import { mapFields } from 'vuex-map-fields'
import { EVENTS } from '~/utils/consts'

export default {
    computed: {
        ...mapFields([
            'showCreditIntermediaryInfo',
        ]),
    },
    watch: {
        showCreditIntermediaryInfo (value) {
            if (value) {
                this.$analytics(EVENTS.CREDIT_INTERMEDIARY_INFO_OPEN)
            } else {
                this.$analytics(EVENTS.CREDIT_INTERMEDIARY_INFO_CLOSE)
            }
        },
    },
}
